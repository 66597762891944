/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */

.site-footer {
	background: linear-gradient(180deg, rgba(38,38,38,0.5) 0%, rgba(0,0,0,0.5) 100%), linear-gradient(180deg, #151844 0%, #111820 100%);
	border-top: 8px solid $orange;
	overflow: hidden;
	clear: both;
	margin-top: auto; // Sticky footer

	&__container {
		padding: #{$spacing*2} $spacing $spacing;
	}

	// Resets
	ul { list-style: none; margin: 0; padding: 0; }

	// Default link styles
	a {
		text-decoration: none;
		
		&:hover { text-decoration: underline; }
	}

	.site-footer__logo svg {
		display: block;
		max-width: 8em;
		padding-bottom: 1em;
	}

	&__content {
		display: inline-block;
	}

	&__business {

		ul, li {
			display: block;
		}
	}

	&__logo {
		display: block;
		margin-bottom: $spacing;
	}

	&__navigation {
		margin: 3rem auto;
		text-align: center;
		text-decoration: none;
        
        .footer-menu__donate a {
            @include button( $white, $buttonColor );
        }

		.footer-menu__item {
			a {padding: .333em .75rem;}
		}

		ul {
			border-bottom: 1px solid rgba($white, .37);
			margin-bottom: 1rem;
			padding-bottom: 1rem;
		}

		li { display: inline-block; }
	}

	.social-media {
		margin: 1em 0;

		&__link {display: inline-block;}

		a {display: block; width: 2em;}

		svg path {fill: $white;}
	}

	&__credits {
		display: flex;
			align-items: flex-end;
			justify-content: space-between;
		padding: 1em 0 0;
		width: 100%;

		.legal-footer {
			display: inline-block;

			ul, li {display: inline-block;}

			.legal-menu__item {
				border-left: 1px solid rgba($white, .37);
				margin-left: .5rem;
				padding-left: .75rem;

				a {font-weight: 400;}
			}
		}
	}

	@include media($screen-sm) {
		&__logo {
			margin-bottom: 0;
			margin-right: $spacing*2;
		}
	}

	@include media($screen-md) {
		&__navigation ul {
			min-width: 40rem;	

			a {font-size: 1.25rem;}
		}
	}
}
