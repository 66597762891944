.layout.layout-featured-cta {
    max-width: 64rem;
}

.featured-cta {
    font-size: 1rem;
    font-weight: normal;

    a {
        text-decoration: none;
    }

    .ctas{
        color: $white;
        padding: 1rem;

        .cta{
            position: relative;
            margin-top: 3.5rem;
            border-radius: 2rem;
            padding: 4rem;
            background: linear-gradient(180deg, #151844 0%, #111820 100%);

            &__subtitle{
                position: absolute;
                top: -0.2rem;  /* position the top  edge of the element at the middle of the parent */
                left: 50%; /* position the left edge of the element at the middle of the parent */
                transform: translate(-50%, -50%);

                margin: 0 auto;
                color: $blueDarker;
                font-size: 1.3rem;
                font-weight: bold;
                background-color: $orangeLight;
                padding: 0.5rem 1rem 0.5rem 1rem;
                border-radius: 1rem;
                text-transform: uppercase;
            }

            .content{
                &__left{
                    position: relative;

                    .h4 {
                        font-size: 1.5rem;
                        color:$white;
                    }

                    .button{
                        display: none;
                        width: auto;
                        color: $blueDarker;
                        background-color: $white;
                        border: 1px solid $white;
                        border-radius: 2rem;
                        padding: 1rem 2.5rem;

                        &:hover,
                        &:focus {
                            background: $blueDarker;
                            color: $white;
                        }
                    }

                    .dottedrectangle {
                        position: absolute;
                        width: 5rem;
                        height: 2rem;
                        background-image: radial-gradient(#eee5e5 0%, transparent 20%), radial-gradient(#b6b1b1 20%, transparent 20%);
                        background-position: 0 0, 30px 30px;
                        background-size: 6px 6px;
                        bottom: -30%;
                        right: 2%;
                        opacity: .55;
                    }
                }

                &__right{
                    margin-top: 1.5rem;
                    text-align: center;

                    .button{
                        margin-top: 1.5rem;
                        color: $blueDarker;
                        background-color: $white;
                        border-radius: 1.9rem;
                    }
                }
            }
        }         
    }
    
    &__heading {
        margin-left: auto;
        margin-right: auto;
        max-width: 25rem;
    }

    @include media ($screen-md) 
    {
        .ctas{
            padding: 1.5rem;

            .cta{
                .content{
                    display: flex;
                    
                    &__left{
                        flex: 1;
                        border-right: 1px solid rgba($white, .37);
                        margin-right: 1.5rem;

                        .button{
                            display: inline-block;
                        }

                        .dottedrectangle {
                            width: 8rem;
                            height: 3rem;
                            bottom: 5%;
                            right: 5%;
                        }
                    }
    
                    &__right{
                        .button{
                            display: none;
                        }

                        .datetime{
                            &__date{
                                font-weight: bold;
                            }
                            &__time{
                                font-size: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}