.categories-cta {
    font-size: 1rem;
    font-weight: normal;
    margin-top: 0;

    a {
        text-decoration: none;
    }

    .title {
        padding: 1.5rem;
        text-align: center;

        > *:not(:last-child) {
            margin-bottom: 1.5rem;
        }

        h1 {
            font-weight: bold;
        }
        
        h2 {
            color: $greenDark;
            font-family: $font-open-sans;
            font-weight: 400;
        }

        .button {
            background-color: #1b5a00;
            border: 1px solid #1b5a00;
            border-radius: 2rem;
            color: #fff;
            display: inline-block;
            font-weight: 700;
            padding: 0.75em 2.5em;
            text-decoration: none;
            transition: 0.25s;
            -webkit-appearance: nowrap;
            white-space: nowrap;

            &:hover,
            &:focus {
                background-color: #fff;
                border-color: #1b5a00;
                color: #1b5a00;
            }
        }

        &__subtitle {
            color: $greenDark;
            font-size: 1.2rem;
        }
    }

    .ctas {
        padding: 1.5rem;

        .cta {
            position: relative;
            overflow: hidden;
            display: block;
            padding: 1.5rem;
            margin-bottom: 0.8rem;
            border-radius: $box-radius;
            background: $blueDark;
            height: 20rem;

            &__title {
                color: $white;
                font-family: $font-open-sans;
                line-height: 2rem;
                white-space: normal;
            }

            // circle shape
            &__circle {
                position: absolute;
                border-radius: 50%;
                width: 6rem;
                height: 6rem;
                background-color: #ff881d;

                &--bt-lf {
                    left: -4rem;
                    bottom: 0;
                    bottom: -1.4rem;
                }
            }

            // semicircle shape
            &__semicircle {
                position: absolute;
                width: 4.5rem;
                height: 2.5rem;
                background-color: #ffb700;
                border-top-left-radius: 4rem;
                border-top-right-radius: 4rem;

                &--rotate-90 {
                    transform: rotate(90deg);
                }

                &--bt-lf {
                    left: 1rem;
                    bottom: 0.6rem;
                }
            }

            // rectangle shape
            &__rectangle {
                position: absolute;
                // transform: rotate(45deg);
                width: 3rem;
                height: 4rem;
                background-color: #3440d1;
                bottom: 0;
                right: 2rem;
            }

            // cross shape
            &__cross {
                position: absolute;
                background: #fb3f38;
                height: 1.5rem;
                width: 0.5rem;
                bottom: 3rem;

                &:after {
                    content: "";
                    position: absolute;
                    background: #fb3f38;
                    height: 0.5rem;
                    width: 1.5rem;
                    top: 0.5rem;
                    right: -0.5rem;
                }

                &--red {
                    background: #fb3f38;
                    &:after {
                        background: #fb3f38;
                    }
                }

                &--blue {
                    background: #3440d1;
                    &:after {
                        background: #3440d1;
                    }
                }

                &--bt-lf {
                    bottom: 6rem;
                    left: 40%;
                }

                &--tp-rt {
                    top: 30%;
                    right: 30%;
                }
            }

            &__parallelogram {
                position: absolute;
                width: 2.5rem;
                height: 5rem;
                transform: skew(-45deg);
                background: #ed1e79;
                // left: -15;
                margin-left: -1.5rem;
                top: 7rem;
            }

            &__dottedrectangle {
                position: absolute;
                width: 8rem;
                height: 2rem;
                background-image: radial-gradient(#eee5e5 0%, transparent 20%),
                    radial-gradient(#b6b1b1 20%, transparent 20%);
                background-position: 0 0, 30px 30px;
                background-size: 6px 6px;

                &--bt-lf {
                    bottom: 20%;
                    right: 20%;
                }

                &--tp-rt {
                    bottom: 40%;
                    right: 30%;
                }
            }
        }

        .cta:hover {
            background: $blueLight;

            .cta {
                &__circle {
                    transition: transform 0.7s ease-in-out;
                }

                &__semicircle {
                    transition: transform 0.8s ease-in-out;
                }

                &__rectangle {
                    @keyframes grow {
                        from {
                            transform: scale(1);
                        }
                        to {
                            transform: scale(3);
                        }
                    }

                    -webkit-transition-property: -webkit-transform;
                    -webkit-transition-duration: 1s;
                    -moz-transition-property: -moz-transform;
                    -moz-transition-duration: 1s;
                    -webkit-animation-name: grow;
                    -webkit-animation-duration: 2s;
                    // -webkit-animation-iteration-count: infinite;
                    -webkit-animation-timing-function: linear;
                    -moz-animation-name: grow;
                    -moz-animation-duration: 2s;
                    // -moz-animation-iteration-count: infinite;
                    -moz-animation-timing-function: linear;

                    transition-property: -moz-transform;
                    transition-duration: 1s;
                    animation-name: grow;
                    animation-duration: 2s;
                    // animation-iteration-count: infinite;
                    animation-timing-function: linear;
                }

                &__cross {
                    transition: transform 0.7s ease-in-out;
                    transform: rotate(360deg);
                }

                &__parallelogram {
                    transition: transform 0.7s ease-in-out;
                }

                &__dottedrectangle {
                    transition: transform 0.7s ease-in-out;
                }
            }
        }
    }

    @include media($screen-md) {
        display: flex;

        .title {
            text-align: start;
            flex: 35%;
        }
        
        .ctas {
            display: flex;
            flex: 65%;
            flex-wrap: wrap;

            .cta {
                margin: 0.5rem;
                flex: calc(70% / 3);
            }
        }
    }
    
    @include media($screen-lg) {
        .title h1 {
            font-size: 2.64rem;
        }
    }
}

.layout {
    margin-top: 0;
    
    @include media($screen-sm) {
        margin-top: 1.5rem;
    }
}

.woocommerce div.product form.cart .button {
    border: 1px solid #a46497;
    border-radius: 2rem;
    padding: 0.75em 2.5em;
	margin-top: 2em;
    -webkit-appearance: nowrap;
    
    &:hover,
    &:focus {
        background-color: #fff;
        border-color: #a46497;
        color: #a46497;
    }
    
    /* Tooltip container */
    .tooltip {
        position: relative;
        display: inline-block;
        border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
    }
}
