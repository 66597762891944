/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
$libraryPath: '/wp-content/themes/recovery-tapers/library';

// Colors
$white: #ffffff;
$black: #000000;
$gray: #dadada;
$grayLighter: #e5e5e5;
$grayLightest: #f0f0f0;
$grayDarker: #404144;
$grayDarkest: #2d2d2d;
$charcoal: #3b4646;
$greenDark: #1B5A00;
$greenLight:  #3D7155;
$blueDark: #063263;
$blueLight: #2C0F6C;
$orange: #FF881D;
$blueDarker: #102647;
$orangeLight: #FF881D;

$primaryColor: $charcoal;
$secondaryColor: $gray;

/**
 * Fonts
 */
$font-open-sans: "Open Sans", "Verdana", "Helvetica", sans-serif;
$font-noto-serif: "Noto Serif", "Times New Roman", "Times" serif;
$bodyFont: $font-open-sans;
$headerFont: $font-noto-serif;

$textColor: $grayDarkest;
$bodyFontSize: 1em;
$bodyLineHeight: 1.65;
$ratioScale: 1.275;
$linkColor: $textColor;

// Heading Sizing
$headingColor: $textColor;
$headingFontSize: 1em;
$headingLineHeight: 1.35;

// Layout Vars
$containSizeMoreNarrow: 50rem;
$containSizeNarrow: 65rem;
$containSize: 80rem;
$containSizeWide: 85rem;

$spacing: 1.5rem;
$vSpacing: 1.5rem;

// Media Query Break Points
$screen-xs:  30.000em;  //  480px
$screen-sm:  48.000em;  //  768px
$screen-md:  62.000em;  //  992px
$screen-lg:  75.000em;  // 1200px
$screen-xlg: 100.000em; // 1600px

// Max Breakpoints
$screen-xs-max:  $screen-xs  - .001em;
$screen-sm-max:  $screen-sm  - .001em;
$screen-md-max:  $screen-md  - .001em;
$screen-lg-max:  $screen-lg  - .001em;
$screen-xlg-max: $screen-xlg - .001em;

$mobileNavBP:    $screen-md; // Break mobile nav styles
$mobileNavBPMax: $screen-md-max;

/**
 * Type style definitions
 */
%default-type {
	color: $textColor;
	font-family: $bodyFont;
	letter-spacing: 0.025em;
}

%button-type {
	@extend %default-type;
	font-weight: bold;
}

%header-type {
	color: $headingColor;
    font-family: $headerFont;
	font-weight: bold;
	line-height: $headingLineHeight;
}

// Radius
$box-radius: 1.9rem;
