.layout-search-form {
    .search-form {
        padding: 1.5rem;

        .title {
            position: relative;
            text-align: center;
    
            h2 {
                font-size: 2rem; 
                font-weight: bold;
                z-index: 2;
            }
    
            h2:after {
                background-image: radial-gradient(#eee5e5 0%, transparent 20%), radial-gradient(#b6b1b1 20%, transparent 20%);
                background-position: 0 0, 50px 50px;
                background-size: 10px 10px;
                content: "";
                display: block;
                height: 4rem;
                left: calc(50% - 5rem);
                margin: 0 auto;
                position: absolute;
                   top: -2rem;
                   z-index: -1;
                width: 10rem;
            }
            
            &__subtitle {
                font-size: 1rem;
                margin-top: 1rem;
                // width: 80%;
            }
        }
    
        .form {
            height: 3rem;
            margin-top: 2rem;
            position: relative;
            width: 100%;
    
            input {
                background-color: #fff;
                border: 1px solid #979797;
                border-radius: 2.5rem;
                box-shadow: 5px 3px 2px #cec8c8;
                color: #000;
                font-size: .7rem;
                height: 3rem;
                margin-bottom: 1rem;
                padding: 0 1rem;
                width: 100%;
            }
    
            button {                
                background-color: #3D7155;
                border: none;
                border-radius: 1.9rem;          // can this be removed?
                border-radius: $box-radius;
                color: $white;
                cursor: pointer;
                font-size: 1rem;
                font-weight: bold;
                height: 2.4rem;
                position: absolute;
                    margin-top: 0.295rem; 
                    padding: 0.5rem 1.5rem;
                    right: 1.5%;

                &:before {
                    background-image: url('#{$libraryPath}/icons/src/search.svg');
                        background-position: right;
                        background-repeat: no-repeat;
                        background-size: contain;
                    opacity: 1;
                }
            }
        }
    }
    
    @include media ($screen-sm) {
        .search-form .form {
            button {
                right: 0.7%;
            }
            
            input {
                font-size: 1rem;
            }
        } 
    }

    @include media ($screen-md) {
        .search-form {
            padding: 0 10% 0 10%;

            .title {
                &__subtitle {
                    width: 60%;
                    margin: 0 auto;
                }
            }
        }
    }
}
