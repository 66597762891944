/**
 * DBS Slate Stylesheet
 * DBS>Interactive
 */
 
span.cart__desktop {
	margin-top: .5rem;
}
	
.home {
	@include media($mobileNavBP) {
		.site-header__container {
			align-items: center;
			flex-direction: column;

			.site-header__branding a {max-width: 10rem;}

			.main-menu .menu__item.toplevel > a {padding: 1.5rem .5rem;}
		}
	}
}