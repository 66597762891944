.layout-recent-talks{
    max-width: 64rem;
}

.recent-talks {
    font-size: 1rem;
    font-weight: normal;
    color: $black;
    text-align: center;

    a {
        text-decoration: none;
    }

    h3{
        overflow-wrap: break-word;
        text-align: center;
        margin: 0 auto;
        padding-bottom: 0.5rem;
    }

    .button{
        display: inline-block;
        margin: 0 auto;
    }

    .products{
        text-align: left;
        padding: 1rem;

        .product{
            border-bottom: 1px solid #9A9A9A;
            padding: 0 0.5rem 1rem 0.5rem;
    
            .content{
                &__left{
                    a{
                        font-size: 1.5rem;
                    }
                }

                &__right{
                    margin-top: 1.5rem;
                    text-align: left;

                    .datetime{
                        &__date{
                            font-weight: bold;
                        }
                        &__time{
                            font-size: 2rem;
                        }
                    }

                    .button-wrapper{
                        //@todo show button
                        display: none;
                    }
                }
            }
        }     
        
        &:last-child{
            border-bottom: 0;
        }
    }

    @include media ($screen-md) 
    {
        h3{
            padding-bottom: 0.8rem;
        }

        .products{
            padding: 0;

            .product{
                margin: 0;
                padding: 0;

                .content{
                    display: flex;
                    
                    &__left{
                        flex: 60%;
                        margin-right: 1.5rem;
                        padding: 1.8rem;
                    }

                    &__right{
                        display: flex;
                        flex: 40%;
                        align-items: center;
                        justify-content: right;
                        text-align: right;

                        padding: 1.8rem;
                        margin-top: 0;

                        .button-wrapper{
                            padding: 2rem;
                            .button {
                                display: none;
                            }
                        }
                    }

                    &__right:hover{
                        flex: 40%;
                        position:relative;
                   
                        .button-wrapper{
                            position: absolute;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-left: 1 solid #A0A0A0;

                            width: 100%;
                            height: 100%;
                            background: linear-gradient(to right, #F0F0F0, transparent);

                            .button {
                                display: inline-block;
                            }
                        }
                    }
                }
            }

            &:first-child{
                margin-top: 1.5rem;
            }
        }

        .read-more{
            margin-top: 1.5rem;
        }
    }
}